@mixin VirshTable {
  .virsh-table {
    .name-col {
      @include breakpoint-widths(50%, 30%, 30%, 30%, 35%);
    }

    .vms-col {
      @include breakpoint-widths(0, 0, 0, 0, 3.5rem);
    }

    .tags-col {
      @include breakpoint-widths(0, 35%, 35%, 35%, 35%);
    }

    .pool-col {
      @include breakpoint-widths(0, 0, 0, 0, 30%);
    }

    .cpu-col {
      @include breakpoint-widths(0, 0, 5rem, 9rem, 9rem);
    }

    .ram-col {
      @include breakpoint-widths(0, 0, 8rem, 11rem, 11rem);
    }

    .storage-col {
      @include breakpoint-widths(0, 0, 8rem, 11rem, 11rem);
    }
  }
}
