@mixin MachineSummary {
  .machine-summary__cards {
    @extend %base-grid;
    grid:
      [row1-start] "overview-card overview-card overview-card overview-card" min-content [row1-end]
      [row2-start] "system-card numa-card network-card network-card" min-content [row2-end]
      [row3-start] "system-card numa-card workload-card workload-card" min-content [row3-end]
      / 1fr 1fr 1fr 1fr;
    padding-left: 0;
    padding-right: 0;

    @media only screen and (max-width: $breakpoint-x-large) {
      grid:
        [row1-start] "overview-card overview-card overview-card" min-content [row1-end]
        [row2-start] "system-card network-card network-card" max-content [row2-end]
        [row3-start] "system-card network-card network-card" min-content [row3-end]
        [row4-start] "numa-card workload-card workload-card" min-content [row4-end]
        / 1fr 1fr 1fr;
    }

    @media only screen and (max-width: $breakpoint-large) {
      grid:
        [row1-start] "overview-card overview-card" min-content [row1-end]
        [row2-start] "network-card network-card" min-content [row2-end]
        [row3-start] "system-card numa-card" min-content [row3-end]
        [row4-start] "workload-card workload-card" min-content [row4-end]
        / 1fr 1fr;
    }

    @media only screen and (max-width: $breakpoint-medium) {
      grid:
        [row1-start] "overview-card" min-content [row1-end]
        [row2-start] "network-card" min-content [row2-end]
        [row3-start] "system-card" min-content [row3-end]
        [row4-start] "numa-card" min-content [row4-end]
        [row5-start] "workload-card" min-content [row5-end]
        / 1fr;
    }
  }

  .machine-summary__overview-card {
    grid-area: overview-card;
    padding: 0;
  }

  .machine-summary__system-card {
    grid-area: system-card;
  }

  .machine-summary__numa-card {
    grid-area: numa-card;
  }

  .machine-summary__network-card {
    grid-area: network-card;
  }

  .machine-summary__workload-card {
    grid-area: workload-card;

    .p-list--labelled .p-list__item-value {
      flex-grow: 3;
    }
  }
}
