@mixin Meter {
  $meter-height: $sp-unit * 1.75;
  $meter-height--small: $sp-unit * 1.5;

  .p-meter {
    margin-bottom: $sp-unit * 1.5;
    padding-top: $sp-unit * 0.75;
  }

  .p-meter__bar {
    border-radius: $meter-height;
    height: $meter-height;
    overflow: hidden;
    position: relative;
    width: 100%;
  }

  .p-meter__label {
    display: flex;
    justify-content: space-between;
    margin-bottom: -#{$sp-unit * 0.25};
    padding-top: $sp-unit * 0.5;
  }

  .p-meter__filled {
    height: 100%;
    position: absolute;
    width: 0%;
  }

  .p-meter__separators {
    height: 100%;
    position: absolute;
    width: 100%;
    z-index: 1;
  }

  .p-meter--small {
    margin-bottom: $sp-unit * 1.75;
    padding-top: $sp-unit * 0.75;

    .p-meter__bar {
      border-radius: $meter-height--small;
      height: $meter-height--small;
      margin-bottom: $sp-unit * 0.75;
    }

    .p-meter__label {
      margin-bottom: 0;
      padding-top: 0;
    }
  }
}
