@mixin maas-utilities {
  // Vertically align checkboxes in double row table headers
  .u-align-header-checkbox {
    top: -$form-tick-box-nudge;
  }

  .u-default-text {
    color: $color-dark !important;
    font-size: 1rem !important;
    font-weight: 300 !important;
    text-transform: none !important;
  }

  .u-display-inline {
    display: inline;
  }

  .u-display-inline-block {
    display: inline-block !important;
  }

  .u-flex {
    display: flex;
  }

  .u-flex--grow {
    flex: 1;
  }

  .u-flex--between {
    display: flex;
    justify-content: space-between;
  }

  .u-flex--vertically {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .u-flex--column-align-end {
    align-items: flex-end;
    display: flex !important;
    flex-direction: column;
  }

  .u-flex--column-x-small {
    @media only screen and (max-width: $breakpoint-x-small) {
      flex-direction: column;
    }
  }

  .u-flex--wrap {
    display: flex;
    flex-wrap: wrap;
  }

  .u-mirror--y {
    transform: rotate(180deg);
  }

  .u-no-text-transform {
    text-transform: none !important;
  }

  .u-nudge-down {
    padding-top: $sph-inner !important;
  }

  .u-nudge-down--small {
    padding-top: $sph-inner--small !important;
  }

  .u-nudge-down--x-small {
    padding-top: $sp-x-small !important;
  }

  .u-nudge-left--x-large {
    padding-right: $sph-inner * 2 !important;
  }

  .u-nudge-left--large {
    padding-right: $sph-inner--large !important;
  }

  .u-nudge-left {
    padding-right: $sph-inner !important;
  }

  .u-nudge-left--small {
    padding-right: $sph-inner--small !important;
  }

  .u-nudge-left--x-small {
    padding-right: $sp-x-small !important;
  }

  .u-nudge-right--x-large {
    padding-left: $sph-inner * 2 !important;
  }

  .u-nudge-right--large {
    padding-left: $sph-inner--large !important;
  }

  .u-nudge-right {
    padding-left: $sph-inner !important;
  }

  .u-nudge-right--small {
    padding-left: $sph-inner--small !important;
  }

  .u-nudge-right--x-small {
    padding-left: $sp-x-small !important;
  }

  .u-rotate-left {
    transform: rotate(-90deg) !important;
  }

  .u-rotate-right {
    transform: rotate(90deg) !important;
  }

  .u-text--light {
    color: $color-mid-dark !important;
  }

  .u-upper-case--first:first-letter {
    text-transform: capitalize;
  }

  .u-width--auto {
    width: auto;
  }
}
