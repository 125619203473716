@mixin StorageResources {
  .storage-resources {
    padding: $spv-inner--medium $sph-inner;
  }

  .storage-resources__content {
    display: flex;
    flex-direction: column;
  }

  .storage-resources__usage {
    border-bottom: $border;
    display: flex;
    margin-bottom: $spv-inner--medium;
  }

  .storage-resources__pools {
    flex-grow: 1;
    overflow: auto;
  }

  @media only screen and (min-width: $breakpoint-medium) {
    .storage-resources__header {
      margin-right: $sph-inner--large;
    }

    .storage-resources__content {
      flex-direction: column;
    }
  }

  @media only screen and (min-width: $breakpoint-large) {
    .storage-resources__content {
      flex-direction: row;
    }

    .storage-resources__usage {
      border-bottom: 0;
      border-right: $border;
      flex-direction: column;
      margin-bottom: 0;
      margin-right: $sph-inner;
    }
  }
}
