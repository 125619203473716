/**
 * Vanilla overrides.
 * {Date} {Person}: {Description of issue}
 * {Link to relevant GitHub issue}
 */

// 09-12-2019 Huw: Truncate does not show ellipsis with expanding tables.
// https://github.com/canonical-web-and-design/vanilla-framework/issues/2697
.p-table-expanding td.u-truncate {
  display: block;
}

// 27-11-2019 Caleb: Tooltips cannot be used with React Portals.
// https://github.com/canonical-web-and-design/vanilla-framework/issues/2672
.p-tooltip__message--portal {
  display: inline;
}

// 03-02-2020 Caleb: Cannot give label a className when using Input component.
// https://github.com/canonical-web-and-design/react-components/issues/67
input[type="checkbox"].has-inline-label + label {
  display: inline;
  padding-top: 0;

  &::before {
    left: 0rem;
    top: 0.125rem;
  }

  &::after {
    left: 0.1875rem;
    top: 0.375rem;
  }
}

// 10-03-2020 Caleb: Spacing around checkboxes/radios too tight when $multi = 1
// https://github.com/canonical-web-and-design/vanilla-framework/issues/2913
input[type="checkbox"],
input[type="radio"] {
  + label {
    margin-bottom: 0.6rem;

    .p-list__item:not(:first-child) &,
    .p-form__group:not(:first-child) & {
      margin-top: -0.5rem;
    }
  }
}
.p-form-help-text,
.p-form-validation__message {
  line-height: 1.5rem;
  margin-bottom: 0.8rem;
  margin-top: -0.5rem;
}

// 10-07-2020 Caleb: Slider input misaligned when using type="number" + wrapper
// should mirror normal form field spacing.
// https://github.com/canonical-web-and-design/vanilla-framework/issues/3163
.p-slider__wrapper {
  margin-bottom: $input-margin-bottom;
}
.p-slider__input {
  -moz-appearance: textfield;
  height: auto;

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}
// 26-03-2021 Huw: Display tooltips above contextual menus.
// https://github.com/canonical-web-and-design/vanilla-framework/issues/3668
.p-tooltip__message {
  // Display tooltips above contextual menus which have a z-index of 9.
  z-index: 10 !important;
}

// 26-03-2021 Huw: Set the background of the back-to-top link to match the grey
// background of maas-ui.
// https://github.com/canonical-web-and-design/vanilla-framework/issues/3667
.p-top .p-top__link {
  background-color: $color-light;
}

// 29-04-2021 Caleb: Remove margin from accordion button in dropdown. Can be
// removed once react-components updated >v0.16.3
.filter-accordion .p-accordion__tab {
  margin-right: 0;
}
