@mixin UsersList {
  $grid-size: 0.1 / 3;

  .user-list td,
  .user-list th {
    // Username:
    &:nth-child(1) {
      flex: $grid-size * 6 0 0;
    }

    // Email
    &:nth-child(2) {
      flex: $grid-size * 6 0 0;
    }

    // Machines
    &:nth-child(3) {
      flex: $grid-size * 3 0 0;
    }

    // Type
    &:nth-child(4) {
      flex: $grid-size * 2 0 0;
    }

    // Last seen
    &:nth-child(5) {
      flex: $grid-size * 5 0 0;
    }

    // Role
    &:nth-child(6) {
      flex: $grid-size * 2 0 0;
    }

    // MAAS keys
    &:nth-child(7) {
      flex: $grid-size * 3 0 0;
    }

    // Actions
    &:nth-child(8) {
      flex: $grid-size * 3 0 0;
    }
  }

  @media screen and (max-width: 1310px) {
    .user-list td,
    .user-list th {
      // Username:
      &:nth-child(1) {
        flex-grow: $grid-size * 8;
      }

      // Email
      &:nth-child(2) {
        flex-grow: $grid-size * 7;
      }

      // MAAS keys
      &:nth-child(7) {
        display: none;
      }
    }
  }

  @media screen and (max-width: 1100px) {
    .user-list td,
    .user-list th {
      // Email
      &:nth-child(2) {
        flex-grow: $grid-size * 9;
      }

      // Machines
      &:nth-child(3) {
        display: none;
      }

      // Role
      &:nth-child(6) {
        flex-grow: $grid-size * 3;
      }
    }
  }

  @media screen and (max-width: 1000px) {
    .user-list td,
    .user-list th {
      // Username:
      &:nth-child(1) {
        flex-grow: $grid-size * 10;
      }

      // Type
      &:nth-child(4) {
        display: none;
      }
    }
  }

  @media screen and (max-width: 860px) {
    .user-list td,
    .user-list th {
      // Username:
      &:nth-child(1) {
        flex-grow: $grid-size * 12;
      }

      // Email
      &:nth-child(2) {
        flex-grow: $grid-size * 11;
      }

      // Last seen
      &:nth-child(5) {
        display: none;
      }

      // Actions
      &:nth-child(8) {
        flex-grow: $grid-size * 4;
      }
    }
  }

  @media screen and (max-width: 570px) {
    .user-list td,
    .user-list th {
      // Username:
      &:nth-child(1) {
        flex-grow: $grid-size * 14;
      }

      // Role
      &:nth-child(6) {
        display: none;
      }
    }
  }

  @media screen and (max-width: 510px) {
    .user-list td,
    .user-list th {
      // Username:
      &:nth-child(1) {
        flex-grow: $grid-size * 20;
      }

      // Email
      &:nth-child(2) {
        display: none;
      }

      // Actions
      &:nth-child(8) {
        flex-grow: $grid-size * 9;
      }
    }
  }
}
