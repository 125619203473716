@mixin maas-links {
  // Keep normal link colour even when visited
  a:visited {
    color: $color-link;

    // Keep contextual menu link color even when visited
    &.p-contextual-menu__link {
      color: $color-dark;
    }
  }
}
