@mixin maas-forms {
  .p-input--closeable {
    position: relative;

    .p-close-input {
      margin: 0;
      position: absolute;
      right: 0;
      top: 0;
    }
  }

  // Align icon size with form validation font size
  .p-form-validation__message [class*="p-icon--"] {
    @extend %icon;
    @include vf-icon-size(#{map-get($font-sizes, small)}rem);
  }

  // Add class to checkbox so that label does not change opacity when disabled
  input[disabled],
  input[disabled="disabled"] {
    &[type="checkbox"].keep-label-opacity + label {
      opacity: 1;

      &::before,
      &::after {
        opacity: 0.5;
      }
    }
  }

  select.u-auto-width {
    min-width: auto;
  }

  .p-checkbox--mixed:checked {
    + label::after {
      border-left: 0;
      top: 0.3125rem;
      transform: none;
    }
  }

  // Custom styling for form fields inside a table.
  .p-form--table {
    input,
    select {
      margin-bottom: 0;
      min-width: 0;
    }

    td {
      padding-bottom: $spv-inner--small;
      padding-top: $spv-inner--small;
    }

    .p-form-validation__message {
      margin-top: 0;
    }

    .tag-selector__dropdown {
      top: initial;
    }

    // Align non-form fields with form fields
    .u-align-non-field {
      padding-top: $sp-unit * 1.75;
    }

    @media screen and (max-width: $breakpoint-medium) {
      tr {
        padding: $spv-inner--small $sph-inner;
        width: 100%;
      }

      td {
        align-items: center;
        padding-left: calc(33% + #{$sph-inner--small});

        .p-form__group {
          flex: 1;
        }

        &[aria-label="Actions"] > * {
          left: calc(-#{$sph-inner--small} - 1px);
          position: relative;
        }
      }
    }
  }
}
