// Vanilla settings:
$grid-max-width: 1440 / 16 * 1rem; // express in rems for easier calculations

$color-navigation-background: #666;
$color-navigation-background--hover: darken($color-navigation-background, 3%);

$breakpoint-x-large: 1440px;
$breakpoint-navigation-threshold: 870px;
$increase-font-size-on-larger-screens: false;

$multi: 1;
