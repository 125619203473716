@mixin VmResources {
  .vm-resources {
    display: flex;
    flex-direction: column;
    padding: $spv-inner--medium $sph-inner;

    @media only screen and (min-width: $breakpoint-large) {
      flex-direction: row;
    }
  }

  .vm-resources__dropdown-container {
    position: relative;
    top: -$spv-inner--x-small;

    .vm-resources__toggle {
      padding-left: $sph-inner--small;
      padding-right: $sph-inner--small;
    }

    @media only screen and (min-width: $breakpoint-large) {
      margin-left: $sph-inner;
    }
  }

  .p-contextual-menu .vm-resources__dropdown {
    max-width: none;
    min-width: 0;
    padding: $spv-inner--x-small $sph-inner--small;
    // Subtract small screen gutter width and card inner padding to center dropdown.
    width: calc(
      100vw - #{(map-get($grid-gutter-widths, small) + $sph-inner) * 2}
    );

    .fqdn-col,
    .status-col {
      width: 50%;
    }

    .power-col,
    .cores-col,
    .ram-col {
      display: none;
    }

    @media only screen and (min-width: $breakpoint-small) {
      width: calc(
        100vw - #{(map-get($grid-gutter-widths, medium) + $sph-inner) * 2}
      );

      .power-col {
        display: table-cell;
        width: 7rem;
      }
    }

    @media only screen and (min-width: $breakpoint-medium) {
      width: 40rem;

      .cores-col,
      .ram-col {
        display: table-cell;
        width: 5rem;
      }
    }
  }
}
