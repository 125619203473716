@mixin TableMenu {
  $icon-size: map-get($icon-sizes, default);
  $icon-space: $icon-size + $sph-inner--small + $sph-inner;

  .p-table-menu--hasIcon .p-contextual-menu__non-interactive {
    padding-left: $icon-space;
  }

  .p-table-menu .p-contextual-menu__dropdown {
    margin-left: -$sph-inner;
    min-width: 13rem;

    .p-table-menu__icon-space,
    [class*="p-icon"] {
      margin-right: $sph-inner--small;
    }
  }

  .p-table-menu--hasIcon .p-contextual-menu__dropdown {
    margin-left: -$icon-space;
  }

  .p-table-menu__toggle {
    line-height: $icon-size;
    padding: 0 $icon-size / 2;
  }

  .p-table-menu__icon-space {
    $icon-space: map-get($icon-sizes, default);
    display: inline-block;
    width: $icon-space;
  }
}
