@mixin maas-contextual-menu {
  // Used to add titles/group headers to the Vanilla contextual menu pattern.
  .p-contextual-menu__non-interactive {
    border-bottom: 1px solid $color-mid-light;
    color: $color-mid-dark;
    font-size: 0.75rem;
    font-weight: 400;
    padding: $spv-inner--x-small $sph-inner;
    text-transform: uppercase;
  }
}
