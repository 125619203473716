@mixin MachineNetworkTable {
  .machine-network-table {
    th,
    td {
      &:nth-child(1) {
        @include breakpoint-widths(0.45, 0.45, 0.26, 0.2, 0.16, true);
      }
      &:nth-child(2) {
        @include breakpoint-widths(0, 0.06, 0.05, 0.05, 0.05, true);
      }
      &:nth-child(3) {
        @include breakpoint-widths(0, 0, 0, 0.16, 0.14, true);
      }
      &:nth-child(4) {
        @include breakpoint-widths(0.45, 0.45, 0.2, 0.15, 0.14, true);
      }
      &:nth-child(5) {
        @include breakpoint-widths(0, 0, 0.17, 0.12, 0.11, true);
      }
      &:nth-child(6) {
        @include breakpoint-widths(0, 0, 0.17, 0.13, 0.12, true);
      }
      &:nth-child(7) {
        @include breakpoint-widths(0, 0, 0, 0.12, 0.11, true);
      }
      &:nth-child(8) {
        @include breakpoint-widths(0, 0, 0, 0, 0.12, true);
      }
      &:nth-child(9) {
        @include breakpoint-widths(0, 0.1, 0.1, 0.06, 0.05, true);
      }
    }
  }
}
