@mixin Section {
  .section {
    display: grid;
    grid-template-rows: auto 1fr;
    height: auto;
  }

  .section__header {
    @extend %vf-has-box-shadow;
    background-color: #fff;
  }

  .section__header .p-icon--spinner {
    height: $spv-inner--large;
    width: $spv-inner--large;
  }

  .section__content {
    // Raise the content above the sidebar so that the card shadows are on top.
    z-index: 1;
  }
}
