@mixin maas-notifications {
  .p-notification--group {
    @extend %vf-has-box-shadow;
    @extend %vf-has-round-corners;
    @extend %vf-card;
    padding: 0;

    [class^="p-notification--"] {
      box-shadow: none;
    }

    [class^="p-notification--"]:first-child {
      margin-bottom: 0;
    }

    [class^="p-notification--"] + [class^="p-notification--"] {
      border-top-left-radius: 0;
      border-top-right-radius: 0;
      border-top: $border;
      margin-bottom: 0;
      margin-top: 0;

      &:before {
        display: none;
      }

      .p-notification__response {
        padding-top: $spv-inner--x-small--scaleable;
      }

      .p-icon--close {
        margin-top: $spv-inner--small;
      }
    }
  }

  .p-notification--has-date,
  .p-notification--has-action {
    .p-notification__response {
      display: flex;
      flex: 1;
    }

    .p-notification__message {
      flex: 1;
    }

    .p-notification__date,
    .p-notification__action {
      flex: 0;
      white-space: nowrap;
      margin-left: $spv-outer--small;
    }
  }

  .p-notification + .p-notification,
  [class^="p-notification--"] + [class^="p-notification--"] {
    margin-top: 0;
  }
}
