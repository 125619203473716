@mixin maas-buttons {
  // Prevent default Vanilla buttons spanning full width on mobile
  @media only screen and (max-width: $breakpoint-x-small) {
    button,
    [class*="p-button"] {
      width: auto;
    }
  }

  // Button styles that make it look like a link
  .p-button--link {
    @extend %vf-button-base;
    background-color: inherit;
    border: 0 !important;
    color: $color-link;
    font-size: inherit;
    line-height: 1rem;
    margin-bottom: 0;
    padding: 0;
    pointer-events: all;
    text-align: left;
    text-transform: inherit;

    &:visited {
      color: $color-link;
    }

    &:hover {
      background-color: inherit;
      color: $color-link;
      text-decoration: underline;
    }

    &:active {
      background-color: inherit !important;
    }

    &:focus {
      color: $color-link;
      outline: 0;
      text-decoration: underline;
    }

    &.is-inline {
      margin: 0;
    }

    // Table header sort buttons
    th & {
      color: $color-dark;
      font-weight: 400;

      .p-icon--contextual-menu {
        @include vf-icon-size(0.875rem);
        margin-left: $sp-x-small;
      }
    }
  }

  table .p-button--link {
    margin: 0;
    padding-bottom: 0;
    padding-top: 0;
  }

  .p-button--link:not(:last-of-type):not(:only-of-type) {
    margin-right: 0;
  }
}
