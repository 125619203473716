@mixin MachineName {
  button.machine-name--editable {
    @extend %vf-heading-4;
    margin-bottom: 0;
    padding: 0 $spv-inner--large;

    &:active,
    &:focus,
    &:hover {
      background-color: transparent;
      cursor: text;
      outline: 1px solid $color-mid-light;
    }
  }

  [type="text"].machine-name__hostname {
    @extend %vf-heading-4;
    box-sizing: content-box;
    margin-bottom: -1px;
    min-width: 3rem;
    // Remove 2px to account for the top and bottom borders.
    padding: calc(#{$sp-xx-small} - 2px) $spv-inner--large
      calc(#{$sp-xx-small} + 1px);
  }
}
