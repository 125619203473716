@mixin NetworkCard {
  .network-card-table {
    margin-bottom: $sp-large;

    th:first-of-type,
    td:first-of-type {
      padding-left: 0;
    }

    th:last-of-type,
    td:last-of-type {
      padding-right: 0;
    }

    .name {
      width: 40%;
    }

    .mac {
      width: 9.5rem;
    }

    .speed {
      width: 7rem;
    }

    .fabric {
      width: 7rem;
    }

    .dhcp {
      width: 60%;
    }

    .sriov {
      width: 3rem;
    }

    @media only screen and (max-width: $breakpoint-medium) {
      .speed,
      .fabric,
      .sriov {
        display: none;
      }
    }
  }
}
